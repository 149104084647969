import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';


// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Charts.vue');

// Dashboard pages
const Dashboard = () =>
  //import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Charts.vue');/*EGM */
import Widgets from 'src/pages/Widgets.vue';



const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');
const PaginatedTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Gepp/client_cartera.vue'); /*EGM*/
const cl_generica = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Gepp/client_generica.vue'); /*EGM*/
const validacion = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Gepp/client_validacion.vue'); /*EGM*/
const cl_report = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Gepp/client_reporte.vue'); /*EGM*/
const reporteD = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Gepp/reporte.vue'); /*EGM*/
const usuarios = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Gepp/usuarios.vue'); /*EGM*/
const callcenter = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Gepp/call_center.vue'); /*EGM*/
const historial = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Gepp/reporte_cartera.vue'); /*EGM*/
const empleados = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Gepp/empleados.vue'); /*EGM*/
const mapa = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Gepp/mapa.vue'); /*EGM*/



let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Ingreso',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Login'
  },
  authPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: Dashboard }
      },
      {
        path: 'charts',
        name: 'Charts',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: Charts }
      },
      {
        path: 'widgets',
        name: 'Widgets',
        components: { default: Widgets }
      },
      {
        path: 'cartera',
        name: 'CARTERA',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: PaginatedTables }
      },
      {
        path: 'generica',
        name: 'CARTERA GENÉRICA',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: cl_generica }
      },
      {
        path: 'validacion',
        name: 'VALIDACIÓN',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: validacion }
      },
      {
        path: 'reporte',
        name: 'REPORTE',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: cl_report }
      },
      {
        path: 'directivo',
        name: 'REPORTE DIRECTIVO',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: reporteD }
      },
      {
        path: 'historial',
        name: 'HISTORIAL',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: historial }
      },
      {
        path: 'usuarios',
        name: 'USUARIOS',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: usuarios }
      },
      {
        path: 'callcenter',
        name: 'CALL CENTER REFRESCO',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: callcenter }
      },
      {
        path: 'empleados',
        name: 'EMPLEADOS CARTERA',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: empleados }
      },
      {
        path: 'mapa',
        name: 'MAPA',
        meta: {
          //hideContent: true,
          hideFooter: true
        },
        components: { default: mapa }
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
